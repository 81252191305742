<template>
    <v-container fluid app v-resize="onResize">
        <v-dialog fullscreen hide-overlay
            v-model="gridDialog"
            transition="dialog-bottom-transition"
            persistent
            no-click-animation
        >
            <v-card class="rounded-0">
                <v-toolbar dark
                    :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                >
                    <v-row no-gutters class="d-flex align-center justify-start mt-1">
                        <v-col class="d-flex justify-start">
                            <div class="d-flex align-start mr-12">
                                <v-img
                                    max-width="120"
                                    max-height="56"
                                    src="img\znap-home.png"
                                ></v-img>
                            </div>
                            <div class="d-flex flex-column align-start">
                                <span class="text-h6 font-weight-regular">{{ selectedSlide.slide_title }}</span>
                                <div class="mb-2 d-flex align-center">
                                    <v-icon small color="white" class="mr-2">mdi-information-outline</v-icon>
                                    <span class="text-caption">{{ report ? report.dynamic_report : 'Descrição do relatório' }}</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-spacer />

                    <template v-if="!showControllers && report.slides.length > 1 && !loadingEvents && !slideTime">
                        <v-btn :disabled="page == 0" class="ml-4" icon color="white" @click="changeSlideBtn('0')">
                            <v-icon>mdi-chevron-double-left</v-icon>
                        </v-btn>
                        <v-btn :disabled="page == 0" icon color="white" @click="changeSlideBtn('--')">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn :disabled="page == report.slides.length-1" icon color="white" @click="changeSlideBtn('++')">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-btn :disabled="page == report.slides.length-1" icon color="white" class="mr-4" @click="changeSlideBtn('-1')">
                            <v-icon>mdi-chevron-double-right</v-icon>
                        </v-btn>
                    </template>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                v-on="on"
                                min-width="48px"
                                depressed
                                @click="refresh()"
                                :disabled="noEventSelected"
                            >
                                <v-icon class="white--text">mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                v-on="on"
                                min-width="48px"
                                depressed
                                @click="exportSheet()"
                                :loading="exporting"
                            >
                                <v-icon class="white--text">mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar Excel</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                v-on="on"
                                min-width="48px"
                                @click.native="drawer = true"
                                depressed
                            >
                                <v-icon class="white--text">mdi-comment-text-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>Comentários</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="transparent" class="mb-2 mr-4 mt-2"
                                v-on="on"
                                min-width="48px"
                                @click="showControllers = !showControllers"
                                depressed
                            >
                                <v-icon v-if="showControllers" class="white--text">mdi-eye-off</v-icon>
                                <v-icon v-else class="white--text">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="showControllers">Esconder controles de slide</span>
                        <span v-else>Mostrar controles de slide</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="transparent" class="mb-2 mt-2"
                                v-on="on"
                                min-width="48px"
                                @click="$emit('closeGridDialog')"
                                depressed
                            >
                                <v-icon class="white--text">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </v-toolbar>

                <v-container fluid fill-height>
                    <v-layout v-if="showControllers">
                        <v-flex xs4>
                            <v-select
                                :items="allEvents"
                                v-model="events"
                                dense
                                v-if="variation.id === 1 || variation.id === 4"
                                class="ml-2 mt-5 mr-1 mb-4"
                                item-text="text"
                                return-object
                                label="Eventos"
                                multiple
                                chips
                                hint="Selecione os eventos"
                                persistent-hint
                            ></v-select>

                            <v-layout v-if="variation.id === 5 || variation.id === 6" wrap>
                                <v-flex xs6>
                                    <v-select
                                        v-model="event1"
                                        :items="allEvents"
                                        class="mx-2 my-4"
                                        item-text="text"
                                        return-object
                                        label="Evento 1"
                                        hint="Selecione o primeiro evento"
                                        persistent-hint
                                        @change="verifySelectedEvent($event)"
                                        clearable
                                    ></v-select>
                                </v-flex>

                                <v-flex xs6>
                                    <v-select
                                        v-model="event2"
                                        :items="allEvents"
                                        class="mx-2 my-4"
                                        item-text="text"
                                        return-object
                                        label="Evento 2"
                                        hint="Selecione o segundo evento para comparar"
                                        persistent-hint
                                        :disabled="!event1"
                                        @change="verifySelectedEvent($event)"
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs3 v-if="report.slides.length > 1">
                            <v-select
                                v-model="slideTime"
                                :items="time"
                                class="mx-4 my-4 comboBox"
                                item-text="text"
                                item-value="time"                
                                label="Tempo de troca do slide"
                                hint="Selecione o tempo em segundos"
                                persistent-hint
                            ></v-select>
                        </v-flex>

                        <v-flex xs3 v-if="report.slides.length > 1">
                            <v-select
                                v-model="selectedSlide"
                                :items="report.slides"
                                class="mx-2 my-4 comboBox"
                                item-text="slide_title"
                                item-value="id_dynamic_report_slide"               
                                label="Slide"
                                hint="Selecione um dos slides"
                                return-object
                                persistent-hint
                                @change="changeSlideInput($event)"
                                :readonly="slideTime !== null"
                            ></v-select>
                        </v-flex>

                        <v-flex xs2 v-if="report.slides.length > 1 && !loadingEvents && !slideTime">
                            <v-btn :disabled="page == 0" class="ml-4 mt-6" icon color="primary" @click="changeSlideBtn('0')">
                                <v-icon>mdi-chevron-double-left</v-icon>
                            </v-btn>
                            <v-btn :disabled="page == 0" class="mt-6" icon color="primary" @click="changeSlideBtn('--')">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn :disabled="page == report.slides.length-1" class="mt-6" icon color="primary" @click="changeSlideBtn('++')">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                            <v-btn :disabled="page == report.slides.length-1" class="mt-6" icon color="primary" @click="changeSlideBtn('-1')">
                                <v-icon>mdi-chevron-double-right</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-carousel
                        v-if="!loadingEvents && report.slides.length > 0"
                        v-model="page"
                        :cycle="slideTime !== null"
                        :interval="slideTime"
                        height="auto"
                        :show-arrows="false"
                        hide-delimiters
                    >
                        <v-carousel-item
                            v-for="slide in report.slides"
                            :key="slide.id_dynamic_report_slide"
                        >
                            <v-sheet v-if="loading" :height="windowSize.y - 64 - 25" class="rounded-b">
                                <div class="white d-flex justify-center align-center" style="width: 100%; height: 100%;">
                                    <v-btn large text :loading="loading" color="primary">
                                        Carregando
                                    </v-btn>
                                </div>
                            </v-sheet>
                            
                            <v-sheet v-if="!loading" :height="windowSize.y - 64 - 25"
                                class="white px-3 pt-2"
                            >
                                <HotTable ref="hotTable"
                                    licenseKey="1c81c-153e1-cb283-a4838-af03f"
                                    :data="data"
                                    :settings="settings"
                                    :columns="columns"
                                    :hiddenColumns="hiddenColumns"
                                    :colHeaders="colHeaders"
                                    :rowHeaders="true"
                                    :nestedHeaders="nestedHeaders"
                                    :contextMenu="contextMenu"
                                    :dropdownMenu="dropDownMenu"
                                    :manualColumnResize="true"
                                    :multiColumnSorting="true"
                                    :bindRowsWithHeaders="true"
                                    :filters="true"
                                    :comments="true"
                                    :customBorders="true"
                                    :fixedColumnsLeft="setFixedColumnsLeft()"
                                    width="100%"
                                    height="100%"
                                    :columnHeaderHeight="columnHeaderHeight"
                                    :trimWhitespace="false"
                                    :copyPaste="true"
                                    :renderAllRows="false" 
                                />
                            </v-sheet>

                            <v-sheet v-if="selection.id > 1"
                                class="white d-flex align-center justify-center rounded-b px-4 pt-2 pb-6"
                            >
                                <div ref="plotly"
                                    :style="plotlyStyles"
                                ></div>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </v-container>
                <comments-menu-report-preview v-if="drawer"
                    :drawer="drawer"
                    @closeDrawer="drawer=false"
                    :parentPayload="{ id_dynamic_report_slide: report.slides[page].id_dynamic_report_slide, id_event: payload.id_event[0]}"
                ></comments-menu-report-preview>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import { registerLanguageDictionary, ptBR } from 'handsontable/i18n'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import Plotly from 'plotly.js-dist'
import CommentsMenuReportPreview from '../../components/znap/CommentsMenuReportPreview'

export default {
    name: "HotGridReportPreview",

    components: {
        HotTable, CommentsMenuReportPreview
    },

    props: {
        gridDialog: { type: Boolean, required: true },
        report: { type: Object, required: true },
    },

    computed: {
        plotlyStyles() {
            return {
                'width': `${this.windowSize.x - 102}px`,
                'height': `${this.windowSize.y - 64 - 112}px`
            }
        },

        noEventSelected() {
            let noEventSelected = true

            if (this.variation.id === 2 || this.variation.id === 3 || this.variation.id === 5 || this.variation.id === 6) {
                if (this.event1 && this.event2) {
                    if (this.event1 != this.event2) {
                        noEventSelected = false
                    }
                }
            } else {
                if (this.events.length) {
                    noEventSelected = false
                }
            }

            return noEventSelected 
        },
    },

    watch: {
        page(newVal, oldVal) {
            if (this.slideTime && (newVal !== oldVal)) {
                if (newVal === 0) {
                    this.selectedSlide = this.report.slides[0]

                    this.loading = true
                    this.hotInstance = null
                    this.importReport(this.selectedSlide)
                } else {
                    this.changeSlideBtn('++')
                }
            }
        },
    },

    data() {
        return {
            // Hot Table
            hotInstance: null,
            data: [],
            settings: {
                cells: (row, col) => {
                    var cellProperties = {}

                    cellProperties.readOnly = true

                    if (this.rowsStyle[row].css.includes('first-column-only')) {
                        if (this.apiResult.rows[0][col] === 'Conta gerencial') {
                            cellProperties.className = this.rowsStyle[row].css
                        } 
                    } else {
                        cellProperties.className = this.rowsStyle[row].css
                    }

                    if (this.isTotalDateColumn(this.apiResult.rows[0][col])) {
                        cellProperties.className += ` border-sides-1 font-bold`
                    }

                    if (this.rowsStyle[row].type === 'percentage') {
                        if (this.apiResult.rows[0][col] !== 'Ano Fiscal') {
                            cellProperties.numericFormat = {
                                pattern: {
                                    output: "percent",
                                    negative: "parenthesis",
                                    mantissa: 2,
                                },
                                culture: ptBR.languageCode
                            }
                        }
                    }

                    return cellProperties
                },
                beforeCopy: (data) => {
                    let stringData = data.map(d => d.map(c => String(c).replace('.',',')))
                    let excelData = stringData.map(lines => lines.join('\t')).join("\t\r\n")

                    document.addEventListener('copy', function(e) {
                        e.clipboardData.setData('text/plain', excelData);
                        e.preventDefault();
                    })

                    this.$toast.success('Valor copiado para a área de transferência')
                    return false
                },
                language: ptBR.languageCode,
                fillHandle: {
                    autoInserRow: false
                },
            },

            columns: [],
            hiddenColumns: { columns: [], copyPasteEnabled: false },
            colHeaders: [],
            nestedHeaders: [],
            firstNestedColspan: 0,
            columnHeaderHeight: [],

            contextMenu: [
                'copy',
                'hidden_rows_hide',
                'hidden_rows_show',
                'hidden_columns_hide',
                'hidden_columns_show',
                {
                    name() {
                        return 'Estilizar linha'
                    },
                    hidden() {
                        let hidden = true
                        const cellCoords = this.getSelectedLast()
                        if (cellCoords[1] === -1) {
                            hidden = false
                        }
                        return hidden
                    },
                    callback: () => {
                        this.dialogStyles = true
                        const row = this.hotInstance.getSelectedLast()[2]
                        let id = this.apiResult.rows[0].findIndex(r => r === 'ID Conta gerencial')
                        this.editedRowStyles = {
                            id: this.data[row][id],
                            css: this.rowsStyle[row].css,
                            row,
                        }
                    }
                }
            ],
            dropDownMenu: [
                'filter_by_condition',
                'filter_by_condition2',
                'filter_action_bar',
                'filter_operators',
                'filter_by_value'
            ],

            analyseHeaders: [],
            apiResult: {
                headers: [],
                rows: [],
                columns: [],
            },
            filterConditions: {},
            filters: [],
            displayFilters: [],
            selectedDisplayFilters: [],
            loadingFilters: [],
            subtotals: false,
            selection: {},
            variation: {},
            percentageVariation: true,
            absoluteVariation: true,
            variationItems: [
                {id: 1, text: 'Desabilitado'}, 
                // {id: 2, text: 'Diferença'},
                // {id: 3, text: 'Percentual'},
                {id: 4, text: 'Análise Horizontal - Ano'}, // HORIZONTAL_ANALYSIS_PREV_YEAR
                {id: 5, text: 'Análise Horizontal por evento'}, // HORIZONTAL_ANALYSIS_EVENT
                {id: 6, text: 'Análise Horizontal YTD por evento'},
                // {id: 7, text: 'Analise Horizontal - Mês'} - HORIZONTAL_ANALYSIS_PREV_MONTH
            ],

            payload: {},
            queryType: "BI_YEAR",
            yearReference: null,

            rowsStyle: [],
            editedRowStyles: null,

            year1: null,
            year2: null,

            // Date filters
            months: [],
            quarters: [],
            semesters: [],
            startingYear: true,
            startingMonthIndex: null,
            year1month: true,
            year1months: [],
            year1quarter: true,
            year1quarters: [],
            year1semester: true,
            year1semesters: [],
            laterYear: true,
            year2month: true,
            year2months: [],
            year2quarter: true,
            year2quarters: [],
            year2semester: true,
            year2semesters: [],
            yearMonthInputStart: null,
            fiscalYearStartMonth: null,
            currentMonth: null,

            allEvents: [],
            events: [],
            event1: null,
            event2: null,
            selectedEvents: [],

            multipleEvents: true,
            calculateDifference: false,
            chartType: {id: 'bar', text: 'Barras'},
            chartTypes: [
                {id: 'bar', text: 'Barras'},
                {id: 'bubble', text: 'Bolhas'},
                {id: 'box', text: 'Box plot'},
                {id: 'waterfall', text: 'Cascata'},
                {id: 'scatter', text: 'Dispersão'},
                {id: 'funnel', text: 'Funíl'},
                {id: 'histogram', text: 'Histograma'},
                {id: 'heatmap', text: 'Mapa de calor'},
                {id: 'pie', text: 'Pizza'},
            ],

            showControllers: true,
            loading: false,
            exporting: false,
            loadingEvents: false,
            selectedSlide: null,

            windowSize: { x: 0, y: 0 },

            // carousel
            step: "1",
            page: "0",
            slideTime: null,
            time: [
                {
                    text: "Desabilitado",
                    time: null
                },
                {
                    text: "5 segundos",
                    time: "5000"
                },
                {
                    text: "15 segundos",
                    time: "15000"
                },
                {
                    text: "30 segundos",
                    time: "30000"
                },
                {
                    text: "60 segundos",
                    time: "60000"
                },
            ],

            // comments
            drawer: false,
        }
    },

    created() {
        this.loading = true
        this.selectedSlide = this.report.slides[0]
        this.importReport(this.selectedSlide)
    },

    mounted() {
        registerLanguageDictionary(ptBR)
        numbro.registerLanguage(languages['pt-BR'])
    },

    methods: {
        refresh() {
            this.loading = true
            this.hotInstance = null
            this.loadGrid()
        },

        async getAllEvents(idAccountGroupVersion) {
            let payload = {
                filter: {
                    orderColumn: 'description',
                    textColumn: 'description',
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_module",
                            operator: "=",
                            value: 5
                        },
                        {
                            AndOr: "AND",
                            column: "id_account_group_version",
                            operator: "=",
                            value: idAccountGroupVersion
                        },
                    ]
                }
            }

            try {
                const res = await this.$http.post(this.$ipEvent + 'event/list-options', payload)
                if (res) {
                    return res.data.rows
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async getEventById(eventId) {
            let payload = {
                filter: {
                    orderColumn: 'description',
                    textColumn: 'description',
                    conditions: [
                        {
                            AndOr: "AND",
                            column: "id_event",
                            operator: "=",
                            value: eventId
                        }
                    ]
                }
            }

            try {
                const res = await this.$http.post(this.$ipEvent + "event/list-options", payload)
                if (res) {
                    return res.data.rows[0]
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        verifySelectedEvent(e) {
            if (e) {
                if (this.event1 && this.event2) {
                    if (this.event1 === this.event2) {
                        this.$toast.error('Os eventos selecionados não podem ser iguais')
                    }
                }
            }
        },

        async importReport(slide) {
            let slideParams = slide.pivot_params_json

            this.payload.id_event = slideParams.events
            this.payload.id_account_group_version = slideParams.id_account_group_version
            this.selection = slideParams.selection
            this.variation = slideParams.variation
            this.absoluteVariation = slideParams.absoluteVariation
            this.percentageVariation = slideParams.percentageVariation
            this.subtotals = slideParams.subtotals
            this.queryType = slideParams.queryType
            this.yearReference = slideParams.yearReference
            this.chartType = slideParams.chartType
            this.analyseHeaders = slideParams.analyseHeaders
            this.filters = slideParams.filters
            this.displayFilters = slideParams.displayFilters
            this.filterConditions = slideParams.filterConditions
            this.year1month = slideParams.year1month,
            this.year1months = slideParams.year1months
            this.year2month = slideParams.year2month,
            this.year2months = slideParams.year2months
            this.year1quarter = slideParams.year1quarter,
            this.year1quarters = slideParams.year1quarters
            this.year2quarter = slideParams.year2quarter,
            this.year2quarters = slideParams.year2quarters
            this.year1semester = slideParams.year1semester,
            this.year1semesters = slideParams.year1semesters
            this.year2semester = slideParams.year2semester,
            this.year2semesters = slideParams.year2semesters
            this.startingYear = slideParams.startingYear
            this.laterYear = slideParams.laterYear

            this.changeVariation(this.variation)

            const allEvents = await this.getAllEvents(this.payload.id_account_group_version)

            if (allEvents) {
                this.events = []
                this.allEvents = allEvents
                let event = null
    
                if (this.multipleEvents) {
                    this.payload.id_event.forEach((e, index) => {
                        let foundEvent = allEvents.find(event => event.id === e)
                        if (index === 0) event = foundEvent
                        this.events.push(foundEvent)
                    })
                } else {
                    this.event1 = allEvents.find(e => e.id === this.payload.id_event[0])
                    event = this.event1
                    this.event2 = allEvents.find(e => e.id === this.payload.id_event[1])
                    this.events = [this.event1, this.event2]
                }

                if (event) {
                    this.payload.id_account_group_version = event.id_account_group_version
                    this.year1 = event.fiscal_year
                    this.yearMonthInputStart = event.year_month_input_start
                    this.fiscalYearStartMonth = event.fiscal_year_start_month
                    this.startingMonthIndex = event.fiscal_year_start_month - 1
                    this.year2 = this.year1 + 1
                }
    
                this.loadGrid()
            }
        },

        changeVariation(e) {
            this.queryType = 'BI_YEAR'

            if (e.id === 1) {
                this.multipleEvents = true
                this.calculateDifference = false
            } else if (e.id === 2) {
                this.multipleEvents = false
                this.calculateDifference = '-'
            } else if (e.id === 3) {
                this.multipleEvents = false
                this.calculateDifference = '%'
            } else if (e.id === 4) {
                this.multipleEvents = true
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_PREV_YEAR'
            } else if (e.id === 5) {
                this.multipleEvents = false
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_EVENT'
            } 
            else if (e.id === 6) {
                this.multipleEvents = false
                this.calculateDifference = false
                this.queryType = 'HORIZONTAL_ANALYSIS_EVENT_TO_DATE'
            }
        },

        isTotalDateColumn(col) {
            for (let i = 0; i <= 4; i++) {
                if (col.includes(`Tri 0${i}`) || col.includes(`Sem 0${i}`) || col.includes('Ano Ano') || col.includes('Total Ano')) {
                    return true
                }
            }
        },

        async loadGrid() {
            this.data = []
            this.columns = []
            this.apiResult = { headers: [], rows: [], columns: [] }
            
            let plotly = this.$refs.plotly
            if (plotly) {
                Plotly.purge(plotly)
            }

            this.hotInstance = null

            let filterConditions = { filter: { conditions: [] }}
            for (let filter in this.filterConditions) {
                if (this.filterConditions[filter].length) {
                    filterConditions.filter.conditions.push({
                        AndOr: "AND",
                        column: filter,
                        operator: "IN",
                        value: this.filterConditions[filter]
                    })
                }
            }

            // this.analyseHeaders.forEach((a, index) => {
            //     if (a.name === 'account_group_description' && a.visible) {
            //         this.analyseHeaders.splice(index, 1)
            //         this.analyseHeaders.unshift(a)
            //     }
            // })

            let accountGroupIndex = this.analyseHeaders.findIndex(a => a.name === 'id_account_group')
            this.analyseHeaders[accountGroupIndex].visible = true

            if (this.variation.id === 2 || this.variation.id === 3) {
                let eventIndex = this.analyseHeaders.findIndex(a => a.name === 'event')
                this.analyseHeaders[eventIndex].visible = true

                let idEventIndex = this.analyseHeaders.findIndex(a => a.name === 'id_event')
                this.analyseHeaders[idEventIndex].visible = true
            }

            let events = null
            if (this.multipleEvents) {
                events = this.events.map(e => e.id)
            } else {
                events = [this.event1.id, this.event2.id]
                this.events = []
                this.events.push(this.event1, this.event2)
            }

            let payload = {
                NoFormulas: true,
                calculateDifference: this.calculateDifference,
                ResultInArray: true,
                queryType: this.queryType,
                subtotals: this.subtotals,
                columns: this.analyseHeaders,
                id_account_group_version: this.payload.id_account_group_version,
                events,
                ...filterConditions
            }

            if (this.variation.id === 6) {
                this.currentMonth = new Date().getMonth()
                payload['monthReference'] = this.currentMonth
            }

            payload['yearReference'] = this.setYearReference(this.variation.id)

            let endpoint = this.setEndpoint()

            try {
                const salesPlanningRes = await this.$http.post(`${this.$ipSalesPlanning}${endpoint}/analyse`, { ...payload })
                if (salesPlanningRes) {
                    this.apiResult.headers = salesPlanningRes.data.headers
                    this.apiResult.columns = salesPlanningRes.data.columns
                    this.apiResult.rows = salesPlanningRes.data.rows

                    if (this.apiResult.rows.length) {
                        this.setColHeaders()
                        this.columns = this.setColumns()
                        this.setHiddenColumns()
                        this.data = this.apiResult.rows.slice(1, this.apiResult.rows.length)
                        this.setRowStyle()

                        this.loading = false
                    } else {
                        this.noDataMessage = "Nenhuma conta gerencial atrelada ao evento"
                        this.loading = false
                    }

                    if (this.selection.id > 1 && this.chartType) {
                        this.hotInstance = null
                        this.$nextTick(() => {
                            this.createPlotly()
                        })
                    }
                }
            } catch (err) {
                this.$fnError(err)
            } 
        },

        setEndpoint() {
            let idPlanningFunction = null
            if (this.variation.id === 1 || this.variation.id === 4) {
                idPlanningFunction = this.events[0].id_planning_function
            } else {
                idPlanningFunction = this.event1.id_planning_function
            }

            if (idPlanningFunction === 1) {
                return 'sales-planning'
            } else if (idPlanningFunction === 2) {
                return 'sales-planning-customer'
            } else {
                return 'employee-allocation-planning'
            }
        },

        setYearReference(variationId) {
            let yearRef = null

            if (variationId === 5) {
                yearRef = this.yearReference
            } else if (variationId === 6) {
                yearRef = 1
            }
            
            return yearRef 
        },

        setRowStyle() {
            this.rowsStyle = []
            let cssIndex = this.apiResult.rows[0].findIndex(r => r === 'CSS')

            this.data.forEach((row, index) => {
                let type = null

                if (row[0] === null) {
                    row[0] = 'Total'
                }

                if (row[0].includes('%')) {
                    type = 'percentage'
                }

                let style = {
                    rowId: index,
                    css: row[cssIndex],
                    type
                }

                this.rowsStyle.push(style)
            })
        },

        setColHeaders() {
            this.colHeaders = []
            this.months = this.$calendar.months()
            this.quarters = this.$calendar.quarters()
            this.semesters = this.$calendar.semesters()

            if (this.variation.id === 5 || this.variation.id === 6) {
                this.apiResult.rows[0].forEach(row => {
                    let colHeader = row

                    let header = this.apiResult.headers.find(h => h.text === row)
                    if (header.valueColumn) {
                        let headerText = ''
                        if (header.value.includes('_event1')) {
                            headerText = this.event1.text
                        } else if (header.value.includes('_event2')) {
                            headerText = this.event2.text
                        } else if (header.value.includes('_variance')) {
                            headerText = "Var.(-)"
                        } else if (header.value.includes('_percentage')) {
                            headerText = "Var.(%)"
                        }
                        
                        colHeader = headerText
                    }

                    this.colHeaders.push(colHeader)
                })
            } else {
                this.apiResult.rows[0].forEach(row => {
                    let colHeader = row
    
                    let header = this.apiResult.headers.find(h => h.text === row)
                    if (header.valueColumn) {
                        let headerText = header.text

                        if (header.value === 'value00') {
                            headerText = "Saldo inicial"
                        } else if(header.value.includes('_variance')){
                            headerText = "Var.(-)"
                        } else if(header.value.includes('_percentage')){
                            headerText = "Var.(%)"
                        } else if (!header.value.includes('year')) {
                            let headerIndex = parseInt(header.value.slice(5, 7), 10)
                            let year = null
                            if (headerIndex > 0 && headerIndex <= 12) {
                                if (this.months[headerIndex - 1].id >= this.fiscalYearStartMonth) {
                                    year = this.year1
                                } else {
                                    year = this.year2
                                }
                                headerText = `${this.months[headerIndex - 1].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            } else if (headerIndex >= 13 && headerIndex <= 24) {
                                if (this.months[headerIndex - 1 - 12].id >= this.fiscalYearStartMonth) {
                                    year = this.year2
                                } else {
                                    year = parseInt(this.year2, 10) + 1
                                }
                                headerText = `${this.months[headerIndex - 1 - 12].text.slice(0,3)}/${year.toString().slice(2,4)}`
                            }
                        }
                        colHeader = headerText
                    }
    
                    this.colHeaders.push(colHeader)
                })
            }

            const dates = ["Janeiro", "Fevereiro", "Março", "1º Trimestre", "Abril", "Maio", "Junho", "2º Trimestre", "1º Semestre", "Julho", "Agosto", "Setembro", "3º Trimestre", "Outubro", "Novembro", "Dezembro", "4º Trimestre", "2º Semestre", "Ano"]

            if (this.variation.id < 4) {
                this.nestedHeaders = false
                this.columnHeaderHeight = 50
            } else if (this.variation.id === 4) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                for (let i = 0; i < dates.length ; i++) {
                    this.nestedHeaders[0].push({ label: dates[i], colspan: 4 })
                }

                this.columnHeaderHeight = [25, 50]
            } else if (this.variation.id === 5) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                let year = this.yearReference === 1 ? this.year1 : this.year2

                for (let i = 0; i < dates.length; i++) {
                    this.nestedHeaders[0].push({ label: `${dates[i]} ${year}`, colspan: 4 })
                }

                for (let i = 0; i < dates.length; i++) {
                    this.nestedHeaders[0].push({ label: `${dates[i]} ${year}`, colspan: 4 })
                }

                this.columnHeaderHeight = [25, 50]
            } else if (this.variation.id === 6) {
                this.nestedHeaders = [[], this.colHeaders]
                this.nestedHeaders[0].push({ label: "", colspan: this.firstNestedColspan })

                let currentMonthText = this.months.find(m => m.id === this.currentMonth).text
                this.nestedHeaders[0].push({ label: currentMonthText, colspan: 4 })

                // let currentQuarterText = this.quarters.find(q => q.id === Math.floor(this.currentMonth / 3  + 1)).text
                // this.nestedHeaders[0].push({ label: currentQuarterText, colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Quarter to date', colspan: 4 })

                // let currentSemesterText = this.semesters.find(s => s.id === Math.floor(this.currentMonth / 6  + 1)).text
                // this.nestedHeaders[0].push({ label: currentSemesterText, colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Semester to date', colspan: 4 })

                // this.nestedHeaders[0].push({ label: 'Ano', colspan: 4 })
                this.nestedHeaders[0].push({ label: 'Year to date', colspan: 4 })

                this.columnHeaderHeight = [25, 50]
            }
        },

        setColumns() {
            let columns = []

            this.apiResult.rows[0].forEach((row, index) => {
                let header = this.analyseHeaders.find(h => h.text === row && h.visible)

                if (header.visible) {
                    let foundColumn = this.apiResult.columns.find(c => c.columnAlias === header.name)
                    let data = index
                    let text = header.text

                    let readOnly = null
                    if (header.editable) {
                        readOnly = false
                    } else {
                        readOnly = true
                    }

                    let type = null
                    let className = ''
                    let numericFormat = null
                    if (foundColumn.columnType === 'VARCHAR') {
                        type = 'text'
                        className = ' htLeft'
                    } else if (foundColumn.columnType === 'INT') {
                        type = 'numeric'
                        className = ' htRight'
                        numericFormat = {
                            pattern: {
                                thousandSeparated: false,
                                mantissa: 0,
                            },
                        }
                    } else {
                        type = 'numeric'
                        className = ' htRight'
                        numericFormat = {
                            pattern: {
                                thousandSeparated: true,
                                negative: "parenthesis",
                                mantissa: 2,
                            },
                            culture: ptBR.languageCode
                        }
                    }

                    const totalColumns = ['year1quarter1', 'year1quarter2', 'year1quarter3', 'year1quarter4', 'year1semester1', 'year1semester2', 'year1',
                        'year2quarter1', 'year2quarter2', 'year2quarter3', 'year2quarter4', 'year2semester1', 'year2semester2', 'year2']

                    if (totalColumns.includes(foundColumn.columnAlias)) {
                        className += ' border-sides-1 font-bold'
                    }

                    columns.push({ data, text, readOnly, className, numericFormat, type})
                }
            })

            return columns
        },

        setHiddenColumns() {
            this.hiddenColumns = { columns: [], copyPasteEnabled: false }
            let cols = []

            this.columns.forEach(c => {
                let header = this.apiResult.headers.find(h => h.text === c.text)

                if (header) {
                    if (header.hideColumn) {
                        cols.push(c.data)
                    }
                }
            })

            if (!this.year1month && this.variation.id === 6) {
                let col = 1
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 12; i++) {
                    let col = null
                    let foundMonth = this.year1months.find(m => m.id === i)
    
                    if (!foundMonth) {
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '
    
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
    
                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 1`)).data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            if (this.variation.id !== 4) {
                for (let i = 1; i <= 12; i++) {
                    let col = null
                    let foundMonth = this.year2months.find(m => m.id === i)
                    
                    if (!foundMonth) {
                        let initialText = i < 10 ? 'Mês 0' : 'Mês '

                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`${initialText}${i} Ano 2`)).data

                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `${initialText}${i} Ano 2`).data

                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            if (!this.year1quarter && this.variation.id === 6) {
                let col = 5
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 4; i++) {
                    let col = null
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
    
                    if (!foundQuarter) {
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 1`)).data
    
                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `Tri 0${i} Ano 1`).data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            for (let i = 1; i <= 4; i++) {
                let col = null
                let foundQuarter = this.year2quarters.find(q => q.id === i)
                
                if (!foundQuarter) {
                    if (this.variation.id >= 4) {
                        col = this.columns.find(c => c.text.includes(`Tri 0${i} Ano 2`)).data

                        if (col) {
                            for (let c = col; c < col + 4; c++) {
                                cols.push(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text === `Tri 0${i} Ano 2`).data
                        
                        if (col) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (!this.year1semester && this.variation.id === 6) {
                let col = 9
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 2; i++) {
                    let col = null
                    let foundSemester = this.year1semesters.find(s => s.id === i)
                    
                    if (!foundSemester) {
                        if (this.variation.id >= 4) {
                            col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 1`)).data

                            if (col) {
                                for (let c = col; c < col + 4; c++) {
                                    cols.push(c)
                                }
                            }
                        } else {
                            col = this.columns.find(c => c.text === `Sem 0${i} Ano 1`).data
                    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            for (let i = 1; i <= 2; i++) {
                let col = null
                let foundSemester = this.year2semesters.find(s => s.id === i)
                
                if (!foundSemester) {
                    if (this.variation.id >= 4) {
                        col = this.columns.find(c => c.text.includes(`Sem 0${i} Ano 2`)).data

                        if (col) {
                            for (let c = col; c < col + 4; c++) {
                                cols.push(c)
                            }
                        }
                    } else {
                        col = this.columns.find(c => c.text === `Sem 0${i} Ano 2`).data
                        
                        if (col) {
                            cols.push(col)
                        }
                    }
                }
            }

            if (!this.startingYear && this.variation.id === 6) {
                let col = 13
                for (let c = col; c < col + 4; c++) {
                    cols.push(c)
                }
            } else {
                for (let i = 1; i <= 2; i++) {
                    let col = null
                    if (i === 1) {
                        if (!this.startingYear) {
                            if (this.variation.id >= 4) {
                                col = this.columns.find(c => c.text.includes(`Total Ano Ano ${i}`)).data
    
                                if (col) {
                                    for (let c = col; c < col + 4; c++) {
                                        cols.push(c)
                                    }
                                }
                            } else {
                                col = this.columns.find(c => c.text === `Total Ano Ano ${i}`).data
    
                                if (col) {
                                    cols.push(col)
                                }
                            }
                        }
                    } else if (i === 2) {
                        if (!this.laterYear) {
                            if (this.variation.id >= 4) {
                                col = this.columns.find(c => c.text.includes(`Total Ano Ano ${i}`)).data
    
                                if (col) {
                                    for (let c = col; c < col + 4; c++) {
                                        cols.push(c)
                                    }
                                }
                            } else {
                                col = this.columns.find(c => c.text === `Total Ano Ano ${i}`).data
    
                                if (col) {
                                    cols.push(col)
                                }
                            }
                        }
                    }
                }
            }

            if (this.variation.id >= 4) {
                if (!this.absoluteVariation) {
                    for (let i = 0; i < this.columns.length; i++) {
                        let col = null

                        if (this.columns[i].text.includes('Var.(-)')) {
                            col = this.columns[i].data
    
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }

                if (!this.percentageVariation) {
                    for (let i = 0; i < this.columns.length; i++) {
                        let col = null

                        if (this.columns[i].text.includes('Var.(%)')) {
                            col = this.columns[i].data
        
                            if (col) {
                                cols.push(col)
                            }
                        }
                    }
                }
            }

            this.hiddenColumns.columns = cols
        },

        createPlotly() {
            let plotly = this.$refs.plotly
            let type = this.chartType ? this.chartType.id : 'bar'
            let data = null
            let layout = null

            if (type === 'bar') {
                let chartProps = this.setBarChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'scatter') {
                let chartProps = this.setScatterChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'pie') {
                let chartProps = this.setPieChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'bubble') {
                let chartProps = this.setBubbleChart()
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'histogram') {
                let chartProps = this.setHistogramChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'heatmap') {
                let chartProps = this.setHeatmapChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'box') {
                let chartProps = this.setBoxChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'funnel') {
                let chartProps = this.setFunnelChart(type)
                data = chartProps.data
                layout = chartProps.layout
            } else if (type === 'waterfall') {
                let chartProps = this.setWaterfallChart(type)
                data = chartProps.data
                layout = chartProps.layout
            }

            let config = {
                // margin: { t: 0 },
                displayModeBar: false,
                responsive: true
            }

            Plotly.newPlot(
                plotly,
                data,
                layout,
                config
            )
        },

        changeSlideInput(e) {
            let foundIndex = this.report.slides.findIndex(s => s.id_dynamic_report_slide === e.id_dynamic_report_slide)
            this.page = foundIndex

            this.loading = true
            this.hotInstance = null
            this.importReport(this.selectedSlide)
        },

        changeSlideBtn(arrow) {
            if (arrow === '0') {
                this.selectedSlide = this.report.slides[0]
                this.page = 0
            } else if (arrow === '--') {
                let foundIndex = this.report.slides.findIndex(s => s.id_dynamic_report_slide === this.selectedSlide.id_dynamic_report_slide)
                this.selectedSlide = this.report.slides[foundIndex - 1]
                this.page = foundIndex - 1
            } else if (arrow === '++') {
                let foundIndex = this.report.slides.findIndex(s => s.id_dynamic_report_slide === this.selectedSlide.id_dynamic_report_slide)
                this.selectedSlide = this.report.slides[foundIndex + 1]
                this.page = foundIndex + 1
            } else if (arrow === '-1') {
                let length = this.report.slides.length
                this.selectedSlide = this.report.slides[length - 1]
                this.page = length - 1
            }

            this.loading = true
            this.hotInstance = null
            this.importReport(this.selectedSlide)
        },

        setFixedColumnsLeft() {
            if (this.apiResult.rows[0][0] === 'Conta gerencial') {
                return 1
            }

            return 0
        },

        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },

        async exportSheet(){
            this.exporting = true

            let filterConditions = { filter: { conditions: [] }}
            for (let filter in this.filterConditions) {
                if (this.filterConditions[filter].length) {
                    filterConditions.filter.conditions.push({
                        AndOr: "AND",
                        column: filter,
                        operator: "IN",
                        value: this.filterConditions[filter]
                    })
                }
            }

            this.hideDateColumnsBeforeExport(this.variation.id)

            // css
            let cssIndex = this.analyseHeaders.findIndex(h => h.name === 'css')
            this.analyseHeaders[cssIndex].visible = false

            // account_order
            let accountOrderIndex = this.analyseHeaders.findIndex(h => h.name === 'account_order')
            this.analyseHeaders[accountOrderIndex].visible = false

            let payload = {
                NoFormulas: true,
                calculateDifference: false,
                ResultInArray: true,
                queryType: this.queryType,
                subtotals: this.subtotals,
                columns: this.analyseHeaders,
                id_account_group_version: this.payload.id_account_group_version,
                events: this.variation.id <= 4 ? this.payload.id_event : [this.event1.id, this.event2.id],
                monthReference: this.variation.id === 6 ? this.currentMonth : null,
                yearReference: this.setYearReference(this.variation.id),
                ...filterConditions
            }

            let endpoint = this.setEndpoint()

            try {
                const res = await this.$http.post(`${this.$ipSalesPlanning}${endpoint}/report-analyse`, { ...payload })
                if (res) {
                    window.open(res.data.token)
                    this.$toast.success(res.data.msg)
                    this.exporting = false
                }
            } catch (err) {
                this.exporting = false
                this.$fnError(err)
            }
        },

        hideDateColumnsBeforeExport(variationId) {
            if (variationId < 4) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    let columnIndex = null
    
                    if (i < 10) {
                        columnIndex = this.analyseHeaders.findIndex(h => h.name === `value0${i}`)
                    } else {
                        columnIndex = this.analyseHeaders.findIndex(h => h.name === `value${i}`)
                    }
    
                    if (foundMonth) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 months
                for (let i = 13; i <= 24; i++) {
                    let foundMonth = this.year2months.find(m => m.id === i - 12)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `value${i}`)
    
                    if (foundMonth) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year1quarter${i}`)
    
                    if (foundQuarter) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year2quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year2quarter${i}`)
    
                    if (foundQuarter) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(s => s.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year1semester${i}`)
    
                    if (foundSemester) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // year 2 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year2semesters.find(s => s.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === `year2semester${i}`)
    
                    if (foundSemester) {
                        this.analyseHeaders[columnIndex].visible = true
                    } else {
                        this.analyseHeaders[columnIndex].visible = false
                    }
                }
    
                // years
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1')
                if (this.startingYear) {
                    this.analyseHeaders[year1Index].visible = true
                } else {
                    this.analyseHeaders[year1Index].visible = false
                }
    
                let year2Index = this.analyseHeaders.findIndex(h => h.name === 'year2')
                if (this.laterYear) {
                    this.analyseHeaders[year2Index].visible = true
                } else {
                    this.analyseHeaders[year2Index].visible = false
                }
            } else if (variationId === 4) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    
                    let index = i < 10 ? `0${i}` : i
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`value${index}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`year1quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name === (`year1semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1')
                if (this.startingYear) {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        let factor = c - year1Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            } else if (variationId === 5) {
                // year 1 months
                for (let i = 1; i <= 12; i++) {
                    let foundMonth = this.year1months.find(m => m.id === i)
                    
                    let index = i < 10 ? `0${i}` : i
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${index}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 months
                for (let i = 13; i <= 24; i++) {
                    let foundMonth = this.year2months.find(m => m.id === i - 12)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${i}`))

                    if (foundMonth) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year1quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 quarters
                for (let i = 1; i <= 4; i++) {
                    let foundQuarter = this.year2quarters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year2quarter${i}`))

                    if (foundQuarter) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 1 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year1semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // year 2 semesters
                for (let i = 1; i <= 2; i++) {
                    let foundSemester = this.year2semesters.find(q => q.id === i)
                    let columnIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year2semester${i}`))

                    if (foundSemester) {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            let factor = c - columnIndex

                            if (factor === 2) {
                                if (this.absoluteVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else if (factor === 3) {
                                if (this.percentageVariation) {
                                    this.analyseHeaders[c].visible = true
                                } else {
                                    this.analyseHeaders[c].visible = false
                                }
                            } else {
                                this.analyseHeaders[c].visible = true
                            }
                        }
                    } else {
                        for (let c = columnIndex; c < columnIndex + 4; c++) {
                            this.analyseHeaders[c].visible = false
                        }
                    }
                }

                // years
                let year1Index = this.analyseHeaders.findIndex(h => h.name === 'year1_event1')
                if (this.startingYear) {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        let factor = c - year1Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year1Index; c < year1Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
    
                let year2Index = this.analyseHeaders.findIndex(h => h.name === 'year2_event1')
                if (this.laterYear) {
                    for (let c = year2Index; c < year2Index + 4; c++) {
                        let factor = c - year2Index

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = year2Index; c < year2Index + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            } else if (variationId === 6) {
                // year 1 month
                let currentMonth = this.currentMonth < 10 ? `0${this.currentMonth}` : this.currentMonth
                let monthIndex = this.analyseHeaders.findIndex(h => h.name.includes(`value${currentMonth}_event1`))
                if (this.year1month) {
                    for (let c = monthIndex; c < monthIndex + 4; c++) {
                        let factor = c - monthIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = monthIndex; c < monthIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1 quarter
                let currentQuarter = Math.ceil(this.currentMonth / 3)
                let quarterIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1quarter${currentQuarter}_event1`))
                if (this.year1quarter) {
                    for (let c = quarterIndex; c < quarterIndex + 4; c++) {
                        let factor = c - quarterIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = quarterIndex; c < quarterIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1 semester
                let currentSemester = Math.ceil(this.currentMonth / 6)
                let semesterIndex = this.analyseHeaders.findIndex(h => h.name.includes(`year1semester${currentSemester}_event1`))
                if (this.year1semester) {
                    for (let c = semesterIndex; c < semesterIndex + 4; c++) {
                        let factor = c - semesterIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = semesterIndex; c < semesterIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }

                // year 1
                let yearIndex = this.analyseHeaders.findIndex(h => h.name === `year1_event1`)
                if (this.startingYear) {
                    for (let c = yearIndex; c < yearIndex + 4; c++) {
                        let factor = c - yearIndex

                        if (factor === 2) {
                            if (this.absoluteVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else if (factor === 3) {
                            if (this.percentageVariation) {
                                this.analyseHeaders[c].visible = true
                            } else {
                                this.analyseHeaders[c].visible = false
                            }
                        } else {
                            this.analyseHeaders[c].visible = true
                        }
                    }
                } else {
                    for (let c = yearIndex; c < yearIndex + 4; c++) {
                        this.analyseHeaders[c].visible = false
                    }
                }
            }
        },
    }
}
</script>

<style src="@/../public/styles/handsontable/handsontable.full.css"></style>